@import '../../../node_modules/wf-react-component-library/build/styles/_colors.scss';

.app-tile {
  margin: 10px;
  width: 240px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.3);
  text-align: center;

  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 $kale;
    cursor: pointer;
  }
}

.app-tile.disabled {
  cursor: not-allowed;
  pointer-events: none;
  background-color: $white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 1.5px 5px 0 rgba(0, 0, 0, 0.2);
}

.app-tile-body {
  background-color: $kale;
  color: $white;
  padding: 10px;
  font-size: 2.5rem;
  font-weight: 500;
  font-family: Corda Std, Georgia, serif;
  display: block;
  -webkit-font-smoothing: antialiased;
}

.app-tile-body.disabled {
  background-color: $lag;
}

.app-tile-footer {
  font-family: Circular Pro, Arial, sans-serif;
  padding: 10px;
  font-size: 0.8rem;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
}
