@import '../node_modules/wf-react-component-library/build/styles/_colors.scss';
@import '../node_modules/wf-react-component-library/build/styles/_icons.scss';
@import '../node_modules/wf-react-component-library/build/styles/_global.scss';

.App {
  text-align: center;
}
.headline-serif {
  color: $chard;
}

.Authenticator {
  margin-top: 50px;
  margin: 0 auto;
}

.page-header {
  padding: 20px;
  border-bottom: solid 2px $chard;
  justify-content: space-between;
}

.body-short-form {
  padding-left: 5px;
}

.page-headline-hero {
  font-family: 'Circular Std', 'Trebuchet MS', 'Helvetica Neue', Arial,
    Helvetica, sans-serif;
  font-size: 52px;
  color: #2e2d2b;
}

.body-short-form {
  font-family: Circular Pro, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 350;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
