.app-tile-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 798px;
  text-align: center;
  margin: 0 auto;
  margin-top: 10px;
}

.app-tile-container-header {
  //justify-content: start;
  max-width: 798px;
  font-size: 1.875rem;
  font-weight: 500;
  margin: 0 auto;
  margin-top: 30px;
  text-align: start;
}
